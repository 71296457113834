/*
| Developed by Starton
| Filename : session.endpoint.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { AxiosRequestConfig } from 'axios'
import { SessionLoginDto } from '@/modules/auth/api/session.dto'
import { User } from '@/modules/user/api/user.dtos'
import { EndpointsService } from '@/services/api/endpoints.service'

/*
|--------------------------------------------------------------------------
| Auth URLs
|--------------------------------------------------------------------------
*/
export const sessionEndpointUrls = {
	me: () => '/session',
	login: () => '/session',
	logout: () => '/session',
} as const

/*
|--------------------------------------------------------------------------
| Auth endpoint
|--------------------------------------------------------------------------
*/
export class SessionEndpoint extends EndpointsService {
	// [GET] - /session - (AUTH)
	// ----------------------------------------------------------------------------
	public static me(options?: AxiosRequestConfig) {
		return this.createEndpointHelper<User>({ ...options, url: sessionEndpointUrls.me(), method: 'GET' })
	}

	// [POST] - /session - (GUEST)
	// ----------------------------------------------------------------------------
	public static store(data: SessionLoginDto, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<User>({
			url: sessionEndpointUrls.login(),
			method: 'POST',
			data,
			...options,
		})
	}

	// [GET] - /session - (AUTH)
	// ----------------------------------------------------------------------------
	public static destroy(options?: AxiosRequestConfig) {
		return this.createEndpointHelper<void>({ ...options, url: sessionEndpointUrls.logout(), method: 'DELETE' })
	}
}
