"use strict";
/*
| Developed by Starton
| Filename : skeleton.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.skeleton = skeleton;
/*
|--------------------------------------------------------------------------
| Skeleton
|--------------------------------------------------------------------------
*/
function skeleton(theme) {
    return {
        MuiSkeleton: {
            defaultProps: {
                animation: 'wave',
                variant: 'rounded',
            },
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.paper,
                },
                rounded: {
                    borderRadius: theme.shape.borderRadius * 2,
                },
            },
        },
    };
}
