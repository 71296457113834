"use strict";
/*
| Developed by Starton
| Filename : tooltip.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.tooltip = tooltip;
/*
|--------------------------------------------------------------------------
| Tooltip
|--------------------------------------------------------------------------
*/
function tooltip(theme) {
    const lightMode = theme.palette.mode === 'light';
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.grey[lightMode ? 800 : 700],
                },
                arrow: {
                    color: theme.palette.grey[lightMode ? 800 : 700],
                },
            },
        },
    };
}
