"use strict";
/*
| Developed by Starton
| Filename : rating.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.rating = rating;
const styles_1 = require("@mui/material/styles");
const Rating_1 = require("@mui/material/Rating");
const SvgIcon_1 = require("@mui/material/SvgIcon");
/*
|--------------------------------------------------------------------------
| Rating
|--------------------------------------------------------------------------
*/
function rating(theme) {
    return {
        MuiRating: {
            styleOverrides: {
                root: {
                    [`&.${Rating_1.ratingClasses.disabled}`]: {
                        opacity: 0.48,
                    },
                },
                iconEmpty: {
                    color: (0, styles_1.alpha)(theme.palette.grey[500], 0.48),
                },
                sizeSmall: {
                    [`& .${SvgIcon_1.svgIconClasses.root}`]: {
                        width: 20,
                        height: 20,
                    },
                },
                sizeMedium: {
                    [`& .${SvgIcon_1.svgIconClasses.root}`]: {
                        width: 24,
                        height: 24,
                    },
                },
                sizeLarge: {
                    [`& .${SvgIcon_1.svgIconClasses.root}`]: {
                        width: 28,
                        height: 28,
                    },
                },
            },
        },
    };
}
