"use strict";
/*
| Developed by Starton
| Filename : chip.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.chip = chip;
const styles_1 = require("@mui/material/styles");
const Chip_1 = require("@mui/material/Chip");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Chip
|--------------------------------------------------------------------------
*/
function chip(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const defaultColor = ownerState.color === 'default';
        const filledVariant = ownerState.variant === 'filled';
        const outlinedVariant = ownerState.variant === 'outlined';
        const softVariant = ownerState.variant === 'soft';
        const defaultStyle = {
            [`& .${Chip_1.chipClasses.deleteIcon}`]: {
                opacity: 0.48,
                color: 'currentColor',
                '&:hover': {
                    opacity: 1,
                    color: 'currentColor',
                },
            },
            ...(defaultColor && {
                [`& .${Chip_1.chipClasses.avatar}`]: {
                    color: theme.palette.text.primary,
                },
                // Filled
                // ----------------------------------------------------------------------------
                ...(filledVariant && {
                    color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                    backgroundColor: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[100],
                    },
                    [`& .${Chip_1.chipClasses.icon}`]: {
                        color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                    },
                }),
                // Outlined
                // ----------------------------------------------------------------------------
                ...(outlinedVariant && {
                    border: `solid 1px ${(0, styles_1.alpha)(theme.palette.grey[500], 0.32)}`,
                }),
                // Soft
                // ----------------------------------------------------------------------------
                ...(softVariant && {
                    color: theme.palette.text.primary,
                    backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.32),
                    },
                }),
            }),
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                [`& .${Chip_1.chipClasses.avatar}`]: {
                    color: theme.palette[color].light,
                    backgroundColor: theme.palette[color].dark,
                },
                // Soft
                // ----------------------------------------------------------------------------
                ...(softVariant && {
                    color: theme.palette[color][lightMode ? 'dark' : 'light'],
                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.16),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.32),
                    },
                }),
            }),
        }));
        const disabledState = {
            [`&.${Chip_1.chipClasses.disabled}`]: {
                opacity: 1,
                color: theme.palette.action.disabled,
                [`& .${Chip_1.chipClasses.icon}`]: {
                    color: theme.palette.action.disabled,
                },
                [`& .${Chip_1.chipClasses.avatar}`]: {
                    color: theme.palette.action.disabled,
                    backgroundColor: theme.palette.action.disabledBackground,
                },
                // Filled
                // ----------------------------------------------------------------------------
                ...(filledVariant && {
                    backgroundColor: theme.palette.action.disabledBackground,
                }),
                // Outlined
                // ----------------------------------------------------------------------------
                ...(outlinedVariant && {
                    borderColor: theme.palette.action.disabledBackground,
                }),
                // Soft
                // ----------------------------------------------------------------------------
                ...(softVariant && {
                    backgroundColor: theme.palette.action.disabledBackground,
                }),
            },
        };
        return [
            defaultStyle,
            ...colorStyle,
            disabledState,
            {
                fontWeight: 500,
                borderRadius: theme.shape.borderRadius,
            },
        ];
    };
    return {
        MuiChip: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
