"use strict";
/*
| Developed by Starton
| Filename : fab.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.fab = fab;
const styles_1 = require("@mui/material/styles");
const Fab_1 = require("@mui/material/Fab");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Fab
|--------------------------------------------------------------------------
*/
function fab(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const defaultColor = ownerState.color === 'default';
        const inheritColor = ownerState.color === 'inherit';
        const circularVariant = ownerState.variant === 'circular';
        const extendedVariant = ownerState.variant === 'extended';
        const outlinedVariant = ownerState.variant === 'outlined';
        const outlinedExtendedVariant = ownerState.variant === 'outlinedExtended';
        const softVariant = ownerState.variant === 'soft';
        const softExtendedVariant = ownerState.variant === 'softExtended';
        const defaultStyle = {
            '&:hover, &:active': {
                boxShadow: 'none',
            },
            // Filled
            // ----------------------------------------------------------------------------
            ...((circularVariant || extendedVariant) && {
                // ...((defaultColor || inheritColor) && {
                // 	boxShadow: theme.customShadows.z8,
                // }),
                ...(inheritColor && {
                    backgroundColor: theme.palette.text.primary,
                    color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                    '&:hover': {
                        backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[400],
                    },
                }),
            }),
            // Outlined
            // ----------------------------------------------------------------------------
            ...((outlinedVariant || outlinedExtendedVariant) && {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                ...((defaultColor || inheritColor) && {
                    border: `solid 1px ${(0, styles_1.alpha)(theme.palette.grey[500], 0.32)}`,
                }),
                ...(defaultColor && {
                    ...(!lightMode && {
                        color: theme.palette.text.secondary,
                    }),
                }),
                '&:hover': {
                    borderColor: 'currentColor',
                    // boxShadow: '0 0 0 0.5px currentColor',
                    backgroundColor: theme.palette.action.hover,
                },
            }),
            // Soft
            // ----------------------------------------------------------------------------
            ...((softVariant || softExtendedVariant) && {
                boxShadow: 'none',
                ...(defaultColor && {
                    color: theme.palette.grey[800],
                    backgroundColor: theme.palette.grey[300],
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400],
                    },
                }),
                ...(inheritColor && {
                    backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.08),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.24),
                    },
                }),
            }),
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                // Filled
                // ----------------------------------------------------------------------------
                ...((circularVariant || extendedVariant) && {
                    // boxShadow: theme.customShadows[color],
                    '&:hover': {
                        backgroundColor: theme.palette[color].dark,
                    },
                }),
                // Outlined
                // ----------------------------------------------------------------------------
                ...((outlinedVariant || outlinedExtendedVariant) && {
                    color: theme.palette[color].main,
                    border: `solid 1px ${(0, styles_1.alpha)(theme.palette[color].main, 0.48)}`,
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.08),
                    },
                }),
                // Soft
                // ----------------------------------------------------------------------------
                ...((softVariant || softExtendedVariant) && {
                    color: theme.palette[color][lightMode ? 'dark' : 'light'],
                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.16),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.32),
                    },
                }),
            }),
        }));
        const disabledState = {
            [`&.${Fab_1.fabClasses.disabled}`]: {
                ...((outlinedVariant || outlinedExtendedVariant) && {
                    backgroundColor: 'transparent',
                    border: `solid 1px ${theme.palette.action.disabledBackground}`,
                }),
            },
        };
        const size = {
            ...(extendedVariant || outlinedExtendedVariant || softExtendedVariant
                ? {
                    width: 'auto',
                    '& svg': {
                        marginRight: theme.spacing(1),
                    },
                    ...(ownerState.size === 'small' && {
                        height: 34,
                        minHeight: 34,
                        borderRadius: 17,
                        padding: theme.spacing(0, 1),
                    }),
                    ...(ownerState.size === 'medium' && {
                        height: 40,
                        minHeight: 40,
                        borderRadius: 20,
                        padding: theme.spacing(0, 2),
                    }),
                    ...(ownerState.size === 'large' && {
                        height: 48,
                        minHeight: 48,
                        borderRadius: 24,
                        padding: theme.spacing(0, 2),
                    }),
                }
                : {
                    ...(ownerState.size === 'small' && {
                        height: 34,
                        minHeight: 34,
                        width: 34,
                        minWidth: 34,
                        borderRadius: 17,
                        padding: theme.spacing(0, 1),
                    }),
                    ...(ownerState.size === 'medium' && {
                        height: 40,
                        minHeight: 40,
                        width: 40,
                        minWidth: 40,
                        borderRadius: 20,
                        padding: theme.spacing(0, 2),
                    }),
                    ...(ownerState.size === 'large' && {
                        height: 48,
                        minHeight: 48,
                        width: 48,
                        minWidth: 48,
                        borderRadius: 24,
                        padding: theme.spacing(0, 2),
                    }),
                }),
        };
        return [defaultStyle, ...colorStyle, disabledState, size];
    };
    return {
        MuiFab: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
