"use strict";
/*
| Developed by Starton
| Filename : alert.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.alert = alert;
const styles_1 = require("@mui/material/styles");
const Alert_1 = require("@mui/material/Alert");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Alert
|--------------------------------------------------------------------------
*/
function alert(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const standardVariant = ownerState.variant === 'standard';
        const filledVariant = ownerState.variant === 'filled';
        const outlinedVariant = ownerState.variant === 'outlined';
        const colorStyle = COLORS.map((color) => ({
            borderRadius: 0,
            ...(ownerState.severity === color && {
                // Standard
                // ---------------------------------------------------------------------------
                ...(standardVariant && {
                    color: theme.palette[color][lightMode ? 'dark' : 'light'],
                    backgroundColor: theme.palette[color][lightMode ? 'light' : 'dark'],
                    [`& .${Alert_1.alertClasses.icon}`]: {
                        color: theme.palette[color][lightMode ? 'main' : 'light'],
                    },
                }),
                // Filled
                // ---------------------------------------------------------------------------
                ...(filledVariant && {
                    color: theme.palette[color].contrastText,
                    backgroundColor: theme.palette[color].main,
                }),
                // Outlined
                // ---------------------------------------------------------------------------
                ...(outlinedVariant && {
                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.08),
                    color: theme.palette[color][lightMode ? 'dark' : 'light'],
                    border: `solid 1px ${(0, styles_1.alpha)(theme.palette[color].main, 0.16)}`,
                    [`& .${Alert_1.alertClasses.icon}`]: {
                        color: theme.palette[color].main,
                    },
                }),
            }),
        }));
        return [...colorStyle];
    };
    return {
        MuiAlert: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
                icon: {
                    opacity: 1,
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginBottom: theme.spacing(0.5),
                    fontWeight: theme.typography.fontWeightSemiBold,
                },
            },
        },
    };
}
