"use strict";
/*
| Developed by Starton
| Filename : css.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideScroll = exports.menuItem = exports.paper = void 0;
exports.bgBlur = bgBlur;
exports.bgGradient = bgGradient;
exports.bgRadial = bgRadial;
exports.textGradient = textGradient;
const styles_1 = require("@mui/material/styles");
const Divider_1 = require("@mui/material/Divider");
const Checkbox_1 = require("@mui/material/Checkbox");
const MenuItem_1 = require("@mui/material/MenuItem");
const Autocomplete_1 = require("@mui/material/Autocomplete");
function bgBlur(props) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;
    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                backgroundColor: (0, styles_1.alpha)(color, opacity),
            },
        };
    }
    return {
        backdropFilter: `blur(${blur}px)`,
        backgroundColor: (0, styles_1.alpha)(color, opacity),
    };
}
function bgGradient(props) {
    const direction = props?.direction || 'to bottom';
    const startColor = props?.startColor;
    const endColor = props?.endColor;
    const imgUrl = props?.imgUrl;
    const color = props?.color;
    if (imgUrl) {
        return {
            background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }
    return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
    };
}
function bgRadial(props) {
    const direction = props.direction || 'circle';
    const startColor = props.startColor;
    const endColor = props.endColor;
    const imgUrl = props.imgUrl;
    const color = props.color;
    if (imgUrl) {
        return {
            background: `radial-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }
    return {
        background: `radial-gradient(${direction}, ${startColor}, ${endColor})`,
    };
}
/*
|--------------------------------------------------------------------------
| Paper
|--------------------------------------------------------------------------
*/
const paper = ({ theme, bgcolor, dropdown }) => ({
    ...bgBlur({
        blur: 20,
        opacity: 0.9,
        color: theme.palette.background.paper,
        ...(!!bgcolor && {
            color: bgcolor,
        }),
    }),
    backgroundColor: `${theme.palette.background.paper}`,
    ...(theme.direction === 'rtl' && {
        backgroundPosition: 'top left, right bottom',
    }),
    ...(dropdown && {
        padding: theme.spacing(0.5),
        boxShadow: 'none',
        borderRadius: theme.shape.borderRadius * 1.25,
    }),
});
exports.paper = paper;
/*
|--------------------------------------------------------------------------
| Menu item
|--------------------------------------------------------------------------
*/
// FIX: Need to find how to solve this type problem.
const menuItem = (theme) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.75, 1),
    borderRadius: theme.shape.borderRadius * 0.75,
    '&:not(:last-of-type)': {
        marginBottom: 4,
    },
    [`&.${MenuItem_1.menuItemClasses.selected}`]: {
        fontWeight: theme.typography.fontWeightSemiBold,
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    [`& .${Checkbox_1.checkboxClasses.root}`]: {
        padding: theme.spacing(0.5),
        marginLeft: theme.spacing(-0.5),
        marginRight: theme.spacing(0.5),
    },
    [`&.${Autocomplete_1.autocompleteClasses.option}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    [`&+.${Divider_1.dividerClasses.root}`]: {
        margin: theme.spacing(0.5, 0),
    },
});
exports.menuItem = menuItem;
/*
|--------------------------------------------------------------------------
| Text gradient
|--------------------------------------------------------------------------
*/
function textGradient(value) {
    return {
        background: `-webkit-linear-gradient(${value})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };
}
/*
|--------------------------------------------------------------------------
| Hide scroll
|--------------------------------------------------------------------------
*/
exports.hideScroll = {
    x: {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    y: {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
};
