"use strict";
/*
| Developed by Starton
| Filename : checkbox.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkbox = checkbox;
const Checkbox_1 = require("@mui/material/Checkbox");
/*
|--------------------------------------------------------------------------
| Checkbox
|--------------------------------------------------------------------------
*/
function checkbox(theme) {
    return {
        MuiCheckbox: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: ({ ownerState }) => {
                    const { color } = ownerState;
                    return {
                        padding: theme.spacing(1),
                        ...(color === 'default' && {
                            [`&.${Checkbox_1.checkboxClasses.checked}`]: {
                                color: theme.palette.text.primary,
                            },
                        }),
                        [`&.${Checkbox_1.checkboxClasses.disabled}`]: {
                            color: theme.palette.action.disabled,
                        },
                    };
                },
            },
        },
    };
}
