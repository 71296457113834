"use strict";
/*
| Developed by Starton
| Filename : paper.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.paper = paper;
const styles_1 = require("@mui/material/styles");
/*
|--------------------------------------------------------------------------
| Paper
|--------------------------------------------------------------------------
*/
function paper(theme) {
    return {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    border: '1px solid',
                    borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                },
                outlined: {
                    borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                },
            },
        },
    };
}
