"use strict";
/*
| Developed by Starton
| Filename : slider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.slider = slider;
const Slider_1 = require("@mui/material/Slider");
/*
|--------------------------------------------------------------------------
| Slider
|--------------------------------------------------------------------------
*/
function slider(theme) {
    const lightMode = theme.palette.mode === 'light';
    return {
        MuiSlider: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    [`&.${Slider_1.sliderClasses.disabled}`]: {
                        color: theme.palette.action.disabled,
                    },
                },
                rail: {
                    opacity: 0.32,
                },
                markLabel: {
                    fontSize: 13,
                    color: theme.palette.text.disabled,
                },
                valueLabel: {
                    borderRadius: 8,
                    backgroundColor: theme.palette.grey[lightMode ? 800 : 700],
                },
            },
        },
    };
}
