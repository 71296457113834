/*
| Developed by Starton
| Filename : FormSubmitButton.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSubmitButton = FormSubmitButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function FormSubmitButton({ form, loadingText = 'Loading...', children, ...props }) {
    return ((0, jsx_runtime_1.jsx)(form.Subscribe, { selector: (state) => [state.isSubmitting], children: ([isSubmitting]) => ((0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", size: "medium", type: "submit", disabled: isSubmitting, startIcon: isSubmitting ? (0, jsx_runtime_1.jsx)(material_1.CircularProgress, { size: 20, color: "inherit" }) : null, ...props, children: isSubmitting ? loadingText : children })) }));
}
