/*
| Developed by Starton
| Filename : typography.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typography = void 0;
exports.remToPx = remToPx;
exports.pxToRem = pxToRem;
exports.responsiveFontSizes = responsiveFontSizes;
/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/
function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}
/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/
function pxToRem(value) {
    return `${value / 16}rem`;
}
/*
|--------------------------------------------------------------------------
| Responsive font sizes
|--------------------------------------------------------------------------
*/
function responsiveFontSizes({ sm, md, lg }) {
    return {
        '@media (minWidth:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (minWidth:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (minWidth:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}
/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
*/
exports.typography = {
    fontFamily: 'var(--font-fira-code)',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 800,
        lineHeight: '150%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(32),
        ...responsiveFontSizes({
            lg: 32,
            md: 28,
            sm: 24,
        }),
    },
    h2: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 600,
        lineHeight: '165%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(24),
        ...responsiveFontSizes({
            lg: 24,
            md: 22,
            sm: 20,
        }),
    },
    h3: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 600,
        lineHeight: '120%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(20),
        ...responsiveFontSizes({
            lg: 20,
            md: 18,
            sm: 16,
        }),
    },
    h4: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 600,
        lineHeight: '150%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({
            lg: 16,
            md: 14,
            sm: 12,
        }),
    },
    h5: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 600,
        lineHeight: '115%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(14),
        ...responsiveFontSizes({
            lg: 14,
            md: 14,
            sm: 14,
        }),
    },
    h6: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 600,
        lineHeight: '135%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(12),
        ...responsiveFontSizes({
            lg: 12,
            md: 12,
            sm: 12,
        }),
    },
    subtitle1: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 500,
        lineHeight: '135%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({
            lg: 16,
            md: 20,
            sm: 16,
        }),
    },
    subtitle2: {
        fontFamily: 'var(--font-space-mono)',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({
            lg: 16,
            md: 16,
            sm: 14,
        }),
    },
    body1: {
        fontFamily: 'var(--font-fira-code)',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: -0.16,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({
            lg: 16,
            md: 16,
            sm: 16,
        }),
    },
    body2: {
        fontFamily: 'var(--font-fira-code)',
        fontWeight: 400,
        lineHeight: '170%',
        letterSpacing: -0.16,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(14),
        ...responsiveFontSizes({
            lg: 14,
            md: 14,
            sm: 14,
        }),
    },
    caption: {
        fontFamily: 'var(--font-fira-code)',
        fontWeight: 400,
        lineHeight: '130%',
        letterSpacing: -0.16,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(12),
        ...responsiveFontSizes({
            lg: 12,
            md: 12,
            sm: 12,
        }),
    },
    overline: {
        fontFamily: 'var(--font-fira-code)',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: 1.328,
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({
            lg: 16,
            md: 16,
            sm: 16,
        }),
    },
    button: {
        fontFamily: 'var(--font-fira-code)',
        fontWeight: 500,
        lineHeight: '100%',
        letterSpacing: -0.16,
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: pxToRem(14),
        ...responsiveFontSizes({
            lg: 14,
            md: 14,
            sm: 14,
        }),
    },
};
