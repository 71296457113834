"use strict";
/*
| Developed by Starton
| Filename : button.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.button = button;
const styles_1 = require("@mui/material/styles");
const Button_1 = require("@mui/material/Button");
/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Button
|--------------------------------------------------------------------------
*/
function button(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const inheritColor = ownerState.color === 'inherit';
        const containedVariant = ownerState.variant === 'contained';
        const outlinedVariant = ownerState.variant === 'outlined';
        const textVariant = ownerState.variant === 'text';
        const softVariant = ownerState.variant === 'soft';
        const smallSize = ownerState.size === 'small';
        const mediumSize = ownerState.size === 'medium';
        const largeSize = ownerState.size === 'large';
        const defaultStyle = {
            borderRadius: 0,
            ...(inheritColor && {
                // Contained
                // ----------------------------------------------------------------------------
                ...(containedVariant && {
                    color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                    backgroundColor: lightMode ? theme.palette.grey[800] : theme.palette.common.white,
                    '&:hover': {
                        backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[400],
                    },
                }),
                // Outlined
                // ----------------------------------------------------------------------------
                ...(outlinedVariant && {
                    borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.32),
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
                // Text
                // ----------------------------------------------------------------------------
                ...(textVariant && {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
                // Soft
                // ----------------------------------------------------------------------------
                ...(softVariant && {
                    color: theme.palette.text.primary,
                    backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.08),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.24),
                    },
                }),
            }),
            ...(outlinedVariant && {
                '&:hover': {
                    borderColor: 'currentColor',
                    boxShadow: '0 0 0 0.5px currentColor',
                },
            }),
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                // Contained
                // ---------------------------------------------------------------------------
                ...(containedVariant && {
                    '&:hover': {
                        boxShadow: 'none',
                    },
                }),
                // Soft
                // ---------------------------------------------------------------------------
                ...(softVariant && {
                    color: theme.palette[color][lightMode ? 'dark' : 'light'],
                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.16),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.32),
                    },
                }),
            }),
        }));
        const disabledState = {
            [`&.${Button_1.buttonClasses.disabled}`]: {
                // Soft
                // ---------------------------------------------------------------------------
                ...(softVariant && {
                    backgroundColor: theme.palette.action.disabledBackground,
                }),
            },
        };
        const size = {
            ...(smallSize && {
                height: 30,
                fontSize: 13,
                paddingLeft: 8,
                paddingRight: 8,
                ...(textVariant && {
                    paddingLeft: 4,
                    paddingRight: 4,
                }),
            }),
            ...(mediumSize && {
                paddingLeft: 12,
                paddingRight: 12,
                ...(textVariant && {
                    paddingLeft: 8,
                    paddingRight: 8,
                }),
            }),
            ...(largeSize && {
                height: 40,
                fontSize: 15,
                paddingLeft: 16,
                paddingRight: 16,
                ...(textVariant && {
                    paddingLeft: 10,
                    paddingRight: 10,
                }),
            }),
        };
        return [defaultStyle, ...colorStyle, disabledState, size];
    };
    return {
        MuiButton: {
            defaultProps: {
                color: 'inherit',
                disableElevation: true,
            },
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
