/*
| Developed by Starton
| Filename : ImageInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dropzone_1 = require("react-dropzone");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
const DropZoneContainer = (0, material_1.styled)(material_1.Box, {
    shouldForwardProp: (prop) => prop !== 'aspectRatio',
})(({ theme, aspectRatio }) => ({
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    minHeight: 150,
    width: '100%',
    aspectRatio: aspectRatio ? `${aspectRatio}/1` : 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border-color 0.2s ease',
    position: 'relative',
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
}));
const PreviewImage = (0, material_1.styled)('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const ImageInput = ({ name, maxSize = 5242880, // 5MB
accept = ['image/jpeg', 'image/png', 'image/webp'], aspectRatio, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, children: (field) => {
            const onDrop = (0, react_1.useCallback)((acceptedFiles) => {
                if (acceptedFiles?.[0]) {
                    field.handleChange?.(acceptedFiles[0]);
                }
            }, [field]);
            const { getRootProps, getInputProps, isDragActive } = (0, react_dropzone_1.useDropzone)({
                onDrop,
                maxSize,
                accept: accept.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
                multiple: false,
            });
            const previewUrl = field?.state?.value instanceof File
                ? URL.createObjectURL(field.state.value)
                : typeof field?.state?.value === 'string'
                    ? field.state.value
                    : null;
            return ((0, jsx_runtime_1.jsxs)(DropZoneContainer, { ...getRootProps(), aspectRatio: aspectRatio, children: [(0, jsx_runtime_1.jsx)("input", { ...getInputProps() }), previewUrl ? ((0, jsx_runtime_1.jsx)(PreviewImage, { src: previewUrl, alt: "Preview", onLoad: () => {
                            if (field?.state?.value instanceof File) {
                                URL.revokeObjectURL(previewUrl);
                            }
                        } })) : ((0, jsx_runtime_1.jsx)(material_1.Typography, { color: "textSecondary", children: isDragActive
                            ? 'Drop the image here'
                            : `Drag and drop an image here, or click to select${aspectRatio ? ` (${aspectRatio}:1 aspect ratio)` : ''}` }))] }));
        } }));
};
exports.ImageInput = ImageInput;
