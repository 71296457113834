"use strict";
/*
| Developed by Starton
| Filename : textfield.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.textField = textField;
const styles_1 = require("@mui/material/styles");
const InputBase_1 = require("@mui/material/InputBase");
const InputLabel_1 = require("@mui/material/InputLabel");
const FilledInput_1 = require("@mui/material/FilledInput");
const OutlinedInput_1 = require("@mui/material/OutlinedInput");
/*
|--------------------------------------------------------------------------
| TextField
|--------------------------------------------------------------------------
*/
function textField(theme) {
    const color = {
        focused: theme.palette.text.primary,
        active: theme.palette.text.secondary,
        placeholder: theme.palette.text.disabled,
    };
    const font = {
        label: theme.typography.body1,
        value: theme.typography.body2,
    };
    return {
        // Form Control
        // ---------------------------------------------------------------------------
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& span.MuiTypography-caption': {
                        marginBottom: theme.spacing(1),
                    },
                },
            },
        },
        // Helper
        // ----------------------------------------------------------------------------
        MuiFormHelperText: {
            defaultProps: {
                component: 'div',
            },
            styleOverrides: {
                root: {
                    marginTop: theme.spacing(1),
                    marginLeft: 0,
                },
            },
        },
        // Label
        // ----------------------------------------------------------------------------
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ...font.value,
                    color: color.placeholder,
                    [`&.${InputLabel_1.inputLabelClasses.shrink}`]: {
                        ...font.label,
                        fontWeight: 600,
                        color: color.active,
                        [`&.${InputLabel_1.inputLabelClasses.focused}`]: {
                            color: color.focused,
                        },
                        [`&.${InputLabel_1.inputLabelClasses.error}`]: {
                            color: theme.palette.error.main,
                        },
                        [`&.${InputLabel_1.inputLabelClasses.disabled}`]: {
                            color: theme.palette.text.disabled,
                        },
                        [`&.${InputLabel_1.inputLabelClasses.filled}`]: {
                            transform: 'translate(12px, 6px) scale(0.75)',
                        },
                    },
                },
            },
        },
        // Base
        // ----------------------------------------------------------------------------
        MuiInputBase: {
            styleOverrides: {
                root: {
                    [`&.${InputBase_1.inputBaseClasses.disabled}`]: {
                        '& svg': {
                            color: theme.palette.text.disabled,
                        },
                    },
                },
                input: {
                    ...font.value,
                    '&::placeholder': {
                        opacity: 1,
                        color: color.placeholder,
                    },
                },
            },
        },
        // Standard
        // ----------------------------------------------------------------------------
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.32),
                    },
                    '&:after': {
                        borderBottomColor: color.focused,
                    },
                },
            },
        },
        // Outlined
        // ----------------------------------------------------------------------------
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: (0, styles_1.alpha)(theme.palette.background.paper, 0.25),
                    backdropFilter: 'blur(20px)',
                    borderRadius: 0,
                    [`&.${OutlinedInput_1.outlinedInputClasses.focused}`]: {
                        [`& .${OutlinedInput_1.outlinedInputClasses.notchedOutline}`]: {
                            borderColor: color.focused,
                        },
                    },
                    [`&.${OutlinedInput_1.outlinedInputClasses.error}`]: {
                        [`& .${OutlinedInput_1.outlinedInputClasses.notchedOutline}`]: {
                            borderColor: theme.palette.error.main,
                        },
                    },
                    [`&.${OutlinedInput_1.outlinedInputClasses.disabled}`]: {
                        [`& .${OutlinedInput_1.outlinedInputClasses.notchedOutline}`]: {
                            borderColor: theme.palette.action.disabledBackground,
                        },
                    },
                },
                notchedOutline: {
                    borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.5),
                    transition: theme.transitions.create(['border-color'], {
                        duration: theme.transitions.duration.shortest,
                    }),
                },
            },
        },
        // Filled
        // ----------------------------------------------------------------------------
        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.08),
                    '&:hover': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                    },
                    [`&.${FilledInput_1.filledInputClasses.focused}`]: {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                    },
                    [`&.${FilledInput_1.filledInputClasses.error}`]: {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.error.main, 0.08),
                        [`&.${FilledInput_1.filledInputClasses.focused}`]: {
                            backgroundColor: (0, styles_1.alpha)(theme.palette.error.main, 0.16),
                        },
                    },
                    [`&.${FilledInput_1.filledInputClasses.disabled}`]: {
                        backgroundColor: theme.palette.action.disabledBackground,
                    },
                },
            },
        },
    };
}
