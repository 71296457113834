"use strict";
/*
| Developed by Starton
| Filename : typography.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.typography = typography;
/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
*/
function typography(theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },
            },
        },
    };
}
