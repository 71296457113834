/*
| Developed by Starton
| Filename : session.hooks.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import useSWR from 'swr'
import { SessionEndpoint } from '@/modules/auth/api/session.endpoint'

/**
 * Get current user
 * @returns Current user
 */
export const useSessionMe = () => {
	const { fetcher, uri } = SessionEndpoint.me()
	return useSWR(uri, fetcher, {
		refreshInterval: 1000 * 60 * 5, // 5 minutes
	})
}
