"use strict";
/*
| Developed by Starton
| Filename : menu.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.menu = menu;
const css_1 = require("../../css");
/*
|--------------------------------------------------------------------------
| Menu
|--------------------------------------------------------------------------
*/
function menu(theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ...(0, css_1.menuItem)(theme),
                },
            },
        },
    };
}
