"use strict";
/*
| Developed by Starton
| Filename : list.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.link = link;
/*
|--------------------------------------------------------------------------
| List
|--------------------------------------------------------------------------
*/
function link(_theme) {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
    };
}
