/*
| Developed by Starton
| Filename : TextInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const TextInput = ({ name, textFieldProps, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, children: (field) => ((0, jsx_runtime_1.jsx)(material_1.TextField, { fullWidth: true, id: `${name}-input`, "data-testid": `${name}-input`, value: field?.state?.value, onChange: (e) => field.handleChange?.(e.target.value), onBlur: field.handleBlur, variant: "outlined", ...textFieldProps })) }));
};
exports.TextInput = TextInput;
