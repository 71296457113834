"use strict";
/*
| Developed by Starton
| Filename : backdrop.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.backdrop = backdrop;
const styles_1 = require("@mui/material/styles");
/*
|--------------------------------------------------------------------------
| Backdrop
|--------------------------------------------------------------------------
*/
function backdrop(theme) {
    return {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: (0, styles_1.alpha)(theme.palette.grey[900], 0.8),
                },
                invisible: {
                    background: 'transparent',
                },
            },
        },
    };
}
