/*
| Developed by Starton
| Filename : Nav.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nav = Nav;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
const NavLogo_1 = require("./NavLogo");
const NavItems_1 = require("./NavItems");
const constants_1 = require("../../../constants");
const providers_1 = require("../../../providers");
/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Drawer = (0, styles_1.styled)(material_1.Drawer, {
    shouldForwardProp: (prop) => prop !== 'drawerWidth',
})(({ drawerWidth }) => ({
    width: drawerWidth || constants_1.NAV_CONFIG.drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    mt: 10,
    [`& .${material_1.drawerClasses.paper}`]: {
        width: drawerWidth || constants_1.NAV_CONFIG.drawerWidth,
        boxSizing: 'border-box',
    },
}));
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function Nav({ beforeTopMenuItems, topMenuItems, afterTopMenuItems, beforeBottomMenuItems, bottomMenuItems, afterBottomMenuItems, drawerWidth, }) {
    const { state } = (0, providers_1.useGlobalContext)();
    return ((0, jsx_runtime_1.jsxs)(Drawer, { variant: "permanent", drawerWidth: drawerWidth, children: [(0, jsx_runtime_1.jsx)(NavLogo_1.NavLogo, {}), state.navigation?.beforeTopMenuItems || beforeTopMenuItems, state.navigation?.topMenuItems || topMenuItems ? ((0, jsx_runtime_1.jsx)(NavItems_1.NavItems, { items: state.navigation?.topMenuItems || topMenuItems || [] })) : null, state.navigation?.afterTopMenuItems || afterTopMenuItems, state.navigation?.beforeBottomMenuItems ||
                beforeBottomMenuItems ||
                state.navigation?.bottomMenuItems ||
                bottomMenuItems ||
                state.navigation?.afterBottomMenuItems ||
                afterBottomMenuItems ? ((0, jsx_runtime_1.jsxs)(material_1.Box, { marginTop: 'auto', display: 'flex', flexDirection: 'column', gap: 1, children: [state.navigation?.beforeBottomMenuItems || beforeBottomMenuItems, state.navigation?.bottomMenuItems || bottomMenuItems ? ((0, jsx_runtime_1.jsx)(NavItems_1.NavItems, { items: state.navigation?.bottomMenuItems || bottomMenuItems || [] })) : null, state.navigation?.afterBottomMenuItems || afterBottomMenuItems] })) : null] }));
}
