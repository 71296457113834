"use strict";
/*
| Developed by Dirupt
| Filename : use-local-storage.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeStorage = exports.setStorage = exports.getStorage = void 0;
exports.useLocalStorage = useLocalStorage;
const react_1 = require("react");
/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
function useLocalStorage(key, initialState) {
    const [state, setState] = (0, react_1.useState)(initialState);
    (0, react_1.useEffect)(() => {
        const restored = (0, exports.getStorage)(key);
        if (restored) {
            setState((prevValue) => ({
                ...prevValue,
                ...restored,
            }));
        }
    }, [key]);
    const updateState = (0, react_1.useCallback)((updateValue) => {
        setState((prevValue) => {
            (0, exports.setStorage)(key, {
                ...prevValue,
                ...updateValue,
            });
            return {
                ...prevValue,
                ...updateValue,
            };
        });
    }, [key]);
    const update = (0, react_1.useCallback)((name, updateValue) => {
        updateState({
            [name]: updateValue,
        });
    }, [updateState]);
    const reset = (0, react_1.useCallback)(() => {
        (0, exports.removeStorage)(key);
        setState(initialState);
    }, [initialState, key]);
    return {
        state,
        update,
        reset,
    };
}
/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/
const getStorage = (key) => {
    let value = null;
    try {
        const result = window.localStorage.getItem(key);
        if (result) {
            value = JSON.parse(result);
        }
    }
    catch (error) {
        console.error(error);
    }
    return value;
};
exports.getStorage = getStorage;
const setStorage = (key, value) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
    catch (error) {
        console.error(error);
    }
};
exports.setStorage = setStorage;
const removeStorage = (key) => {
    try {
        window.localStorage.removeItem(key);
    }
    catch (error) {
        console.error(error);
    }
};
exports.removeStorage = removeStorage;
