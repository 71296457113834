"use strict";
/*
| Developed by Starton
| Filename : stepper.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.stepper = stepper;
/*
|--------------------------------------------------------------------------
| Stepper
|--------------------------------------------------------------------------
*/
function stepper(theme) {
    return {
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: theme.palette.divider,
                },
            },
        },
    };
}
