/*
| Developed by Starton
| Filename : palette.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPalette = exports.dark = exports.light = exports.action = exports.common = exports.error = exports.warning = exports.success = exports.info = exports.primary = exports.grey = void 0;
const styles_1 = require("@mui/material/styles");
/*
|--------------------------------------------------------------------------
| Base Colors
|--------------------------------------------------------------------------
*/
exports.grey = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};
exports.primary = {
    lighter: '#C8FFF4',
    light: '#67FEEE',
    main: '#01FEE2',
    dark: '#01CBB5',
    darker: '#018C7D',
    hover: (0, styles_1.alpha)('#01FEE2', 0.1),
    contrastText: '#161B1F',
};
exports.info = {
    lighter: '#D0F2FF',
    light: '#D6EFFE',
    main: '#30B0FA',
    dark: '#0696E9',
    darker: '#04297A',
    hover: (0, styles_1.alpha)('#30B0FA', 0.1),
    contrastText: '#161B1F',
};
exports.success = {
    lighter: '#E9FCD4',
    light: '#D3F8ED',
    main: '#21DEA5',
    dark: '#1AB284',
    darker: '#08660D',
    hover: (0, styles_1.alpha)('#21DEA5', 0.1),
    contrastText: '#161B1F',
};
exports.warning = {
    lighter: '#FFF7CD',
    light: '#FAE4D1',
    main: '#E5791A',
    dark: '#B76115',
    darker: '#7A4F01',
    hover: (0, styles_1.alpha)('#E5791A', 0.1),
    contrastText: '#161B1F',
};
exports.error = {
    lighter: '#FFE7D9',
    light: '#FCDAE2',
    main: '#F1486F',
    dark: '#EF2B58',
    darker: '#7A0C2E',
    hover: (0, styles_1.alpha)('#F1486F', 0.1),
    contrastText: '#161B1F',
};
exports.common = {
    black: '#000000',
    white: '#FFFFFF',
};
exports.action = {
    active: exports.grey[600],
    hover: (0, styles_1.alpha)(exports.grey[500], 0.08),
    selected: (0, styles_1.alpha)(exports.grey[500], 0.16),
    disabled: (0, styles_1.alpha)(exports.grey[500], 0.8),
    disabledBackground: (0, styles_1.alpha)(exports.grey[500], 0.24),
    focus: (0, styles_1.alpha)(exports.grey[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};
const base = {
    primary: exports.primary,
    info: exports.info,
    success: exports.success,
    warning: exports.warning,
    error: exports.error,
    grey: exports.grey,
    common: exports.common,
    divider: (0, styles_1.alpha)(exports.grey[500], 0.2),
    action: exports.action,
};
/*
|--------------------------------------------------------------------------
| Light Mode Palette
|--------------------------------------------------------------------------
*/
exports.light = {
    ...base,
    mode: 'light',
    primary: {
        lighter: '#A8E0D9',
        light: '#47C0B0',
        main: '#00A190',
        dark: '#007F73',
        darker: '#005750',
        hover: (0, styles_1.alpha)('#00A190', 0.1),
        contrastText: '#FFFFFF',
    },
    secondary: {
        lighter: '#D6E4FF',
        light: '#8799AB',
        main: '#35414C',
        dark: '#1E242A',
        darker: '#091A7A',
        hover: (0, styles_1.alpha)('#A5B3C0', 0.2),
        contrastText: '#F1F3F5',
    },
    text: {
        primary: exports.grey[800],
        secondary: exports.grey[600],
        disabled: exports.grey[500],
    },
    background: {
        paper: exports.common.white,
        default: '#F9F9FA',
        neutral: exports.grey[100],
        neutralLight: '#FBFBFC',
    },
    action: {
        ...base.action,
        active: exports.grey[600],
    },
};
/*
|--------------------------------------------------------------------------
| Dark Mode Palette
|--------------------------------------------------------------------------
*/
exports.dark = {
    ...base,
    mode: 'dark',
    primary: {
        lighter: '#C8FFF4',
        light: '#67FEEE',
        main: '#01FEE2',
        dark: '#01CBB5',
        darker: '#018C7D',
        hover: (0, styles_1.alpha)('#01FEE2', 0.1),
        contrastText: '#161B1F',
    },
    secondary: {
        lighter: '#FFFFFF',
        light: '#FFFFFF',
        main: '#F1F3F5',
        dark: '#A5B3C0',
        darker: '#8799AB',
        hover: (0, styles_1.alpha)('#F1F3F5', 0.2),
        contrastText: '#1E242A',
    },
    text: {
        primary: '#F1F3F5',
        secondary: exports.grey[500],
        disabled: exports.grey[600],
    },
    background: {
        paper: '#1A1F25',
        default: '#1E242A',
        neutral: exports.grey[700],
        neutralLight: exports.grey[600],
    },
    action: {
        ...base.action,
        active: exports.grey[500],
        hover: (0, styles_1.alpha)(exports.grey[500], 0.12),
        selected: (0, styles_1.alpha)(exports.grey[500], 0.24),
        disabled: (0, styles_1.alpha)(exports.grey[500], 0.5),
        focus: (0, styles_1.alpha)(exports.grey[500], 0.32),
    },
};
/**
 * Returns the appropriate palette based on the specified mode
 */
const getPalette = (mode) => (mode === 'light' ? exports.light : exports.dark);
exports.getPalette = getPalette;
