/*
| Developed by Starton
| Filename : UserAvatar.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAvatar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const UserAvatar = ({ username, avatarUrl }) => {
    return ((0, jsx_runtime_1.jsx)(material_1.Card, { children: (0, jsx_runtime_1.jsxs)(material_1.CardContent, { sx: { display: 'flex', alignItems: 'center', p: 2 }, children: [(0, jsx_runtime_1.jsx)(material_1.Avatar, { src: avatarUrl, alt: username, children: username.charAt(0).toUpperCase() }), (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "subtitle1", sx: { ml: 2 }, children: username })] }) }));
};
exports.UserAvatar = UserAvatar;
