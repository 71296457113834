"use strict";
/*
| Developed by Starton
| Filename : popover.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.popover = popover;
const List_1 = require("@mui/material/List");
const css_1 = require("../../css");
/*
|--------------------------------------------------------------------------
| Popover
|--------------------------------------------------------------------------
*/
function popover(theme) {
    return {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    ...(0, css_1.paper)({ theme, dropdown: true }),
                    [`& .${List_1.listClasses.root}`]: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
    };
}
