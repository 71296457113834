/*
| Developed by Starton
| Filename : FormFieldErorrs.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldErorrs = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const FormFieldErorrs = ({ field }) => {
    // Render
    //--------------------------------------------------------------------------
    if (!field.state?.meta?.isTouched || !field.state?.meta?.errors?.length)
        return null;
    return (0, jsx_runtime_1.jsx)(material_1.FormHelperText, { sx: { color: 'error.main' }, children: field.state.meta.errors[0] });
};
exports.FormFieldErorrs = FormFieldErorrs;
