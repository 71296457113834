/*
| Developed by Starton
| Filename : RadioInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const RadioInput = ({ options, name, radioGroupProps, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, children: (field) => ((0, jsx_runtime_1.jsx)(material_1.RadioGroup, { onChange: (e) => field.handleChange(e.target.value), onBlur: field.handleBlur, value: field.state.value, "aria-labelledby": `${name}-input`, defaultValue: "female", name: "radio-buttons-group", "data-testid": `${name}-input`, ...radioGroupProps, sx: {
                display: 'flex',
                flexDirection: 'row',
                ...radioGroupProps?.sx,
            }, children: options?.map((option, index) => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Radio, { size: "small" }), "data-testid": `${name}-input-${option.value}`, ...option }, index))) })) }));
};
exports.RadioInput = RadioInput;
