"use strict";
/*
| Developed by Starton
| Filename : switch.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.switches = switches;
const styles_1 = require("@mui/material/styles");
const Switch_1 = require("@mui/material/Switch");
/*
|--------------------------------------------------------------------------
| Switch
|--------------------------------------------------------------------------
*/
function switches(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const { color } = ownerState;
        return {
            width: 58,
            height: 38,
            padding: '9px 13px 9px 12px',
            [`& .${Switch_1.switchClasses.thumb}`]: {
                width: 14,
                height: 14,
                boxShadow: 'none',
                color: theme.palette.common.white,
            },
            [`& .${Switch_1.switchClasses.track}`]: {
                opacity: 1,
                borderRadius: 14,
                backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.48),
            },
            [`& .${Switch_1.switchClasses.switchBase}`]: {
                left: 3,
                padding: 12,
                [`&.${Switch_1.switchClasses.checked}`]: {
                    transform: 'translateX(13px)',
                    [`& .${Switch_1.switchClasses.thumb}`]: {
                        ...(color === 'default' &&
                            !lightMode && {
                            color: theme.palette.grey[800],
                        }),
                    },
                    [`&+.${Switch_1.switchClasses.track}`]: {
                        opacity: 1,
                        ...(color === 'default' && {
                            backgroundColor: theme.palette.text.primary,
                        }),
                    },
                },
                [`&.${Switch_1.switchClasses.disabled}`]: {
                    [`& .${Switch_1.switchClasses.thumb}`]: {
                        opacity: lightMode ? 1 : 0.48,
                    },
                    [`&+.${Switch_1.switchClasses.track}`]: {
                        opacity: 0.48,
                    },
                },
            },
            // Small
            [`&.${Switch_1.switchClasses.sizeSmall}`]: {
                padding: '4px 8px 4px 7px',
                width: 40,
                height: 24,
                [`& .${Switch_1.switchClasses.thumb}`]: {
                    width: 10,
                    height: 10,
                },
                [`& .${Switch_1.switchClasses.switchBase}`]: {
                    padding: 7,
                    [`&.${Switch_1.switchClasses.checked}`]: {
                        transform: 'translateX(9px)',
                    },
                },
            },
        };
    };
    return {
        MuiSwitch: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
