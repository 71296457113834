"use strict";
/*
| Developed by Starton
| Filename : progress.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.progress = progress;
const styles_1 = require("@mui/material/styles");
const LinearProgress_1 = require("@mui/material/LinearProgress");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Progress
|--------------------------------------------------------------------------
*/
function progress(theme) {
    const rootStyles = (ownerState) => {
        const bufferVariant = ownerState.variant === 'buffer';
        const defaultStyle = {
            borderRadius: 4,
            [`& .${LinearProgress_1.linearProgressClasses.bar}`]: {
                borderRadius: 4,
            },
            ...(bufferVariant && {
                backgroundColor: 'transparent',
            }),
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.24),
            }),
        }));
        return [defaultStyle, ...colorStyle];
    };
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
