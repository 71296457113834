/*
| Developed by Starton
| Filename : AppWrapper.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppWrapper = AppWrapper;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function AppWrapper({ children, ...containerProps }) {
    return ((0, jsx_runtime_1.jsx)(material_1.Box, { display: 'flex', flexDirection: 'row', width: '100vw', maxWidth: '100vw', ...containerProps, children: children }));
}
