"use strict";
/*
| Developed by Starton
| Filename : table.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.table = table;
const styles_1 = require("@mui/material/styles");
const TableRow_1 = require("@mui/material/TableRow");
const TableCell_1 = require("@mui/material/TableCell");
/*
|--------------------------------------------------------------------------
| Table
|--------------------------------------------------------------------------
*/
function table(theme) {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    position: 'relative',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    [`&.${TableRow_1.tableRowClasses.selected}`]: {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.primary.dark, 0.04),
                        '&:hover': {
                            backgroundColor: (0, styles_1.alpha)(theme.palette.primary.dark, 0.08),
                        },
                    },
                    '&:last-of-type': {
                        [`& .${TableCell_1.tableCellClasses.root}`]: {
                            borderColor: 'transparent',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomStyle: 'dashed',
                },
                head: {
                    fontSize: 14,
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightSemiBold,
                    backgroundColor: theme.palette.background.paper,
                },
                stickyHeader: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 100%)`,
                },
                paddingCheckbox: {
                    paddingLeft: theme.spacing(1),
                },
            },
        },
        MuiTablePagination: {
            defaultProps: {
                backIconButtonProps: {
                    size: 'small',
                },
                nextIconButtonProps: {
                    size: 'small',
                },
            },
            styleOverrides: {
                root: {
                    width: '100%',
                },
                toolbar: {
                    height: 64,
                },
                actions: {
                    marginRight: 8,
                },
                select: {
                    paddingLeft: 8,
                    '&:focus': {
                        borderRadius: theme.shape.borderRadius,
                    },
                },
                selectIcon: {
                    right: 4,
                    width: 16,
                    height: 16,
                    top: 'calc(50% - 8px)',
                },
            },
        },
    };
}
