"use strict";
/*
| Developed by Starton
| Filename : drawer.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawer = drawer;
const styles_1 = require("@mui/material/styles");
const Drawer_1 = require("@mui/material/Drawer");
const css_1 = require("../../css");
/*
|--------------------------------------------------------------------------
| Drawer
|--------------------------------------------------------------------------
*/
function drawer(theme) {
    const lightMode = theme.palette.mode === 'light';
    return {
        MuiDrawer: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'temporary' && {
                        [`& .${Drawer_1.drawerClasses.paper}`]: {
                            ...(0, css_1.paper)({ theme }),
                            ...(ownerState.anchor === 'left' && {
                                boxShadow: `40px 40px 80px -8px ${(0, styles_1.alpha)(lightMode ? theme.palette.grey[500] : theme.palette.common.black, 0.24)}`,
                            }),
                            ...(ownerState.anchor === 'right' && {
                                boxShadow: `-40px 40px 80px -8px ${(0, styles_1.alpha)(lightMode ? theme.palette.grey[500] : theme.palette.common.black, 0.24)}`,
                            }),
                        },
                    }),
                }),
            },
        },
    };
}
