/*
| Developed by Starton
| Filename : AutocompleteInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const AutocompleteInput = ({ autocompleteProps, placeholder, name, disabled, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, disabled: disabled, children: (field) => ((0, jsx_runtime_1.jsx)(material_1.Autocomplete, { value: field?.state?.value, onChange: (_, value) => field.handleChange?.(value), onBlur: field.handleBlur, disabled: disabled, renderInput: (params) => ((0, jsx_runtime_1.jsx)(material_1.TextField, { ref: params.InputProps.ref, "data-testid": `${name}-input`, placeholder: placeholder, error: !!field.state.meta.errors?.length && !!field.state.meta.isTouched, ...params, fullWidth: true, id: `${name}-input` })), ...autocompleteProps })) }));
};
exports.AutocompleteInput = AutocompleteInput;
