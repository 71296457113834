"use strict";
/*
| Developed by Starton
| Filename : pagination.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.pagination = pagination;
const styles_1 = require("@mui/material/styles");
const PaginationItem_1 = require("@mui/material/PaginationItem");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| Pagination
|--------------------------------------------------------------------------
*/
function pagination(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const defaultColor = ownerState.color === 'standard';
        const filledVariant = ownerState.variant === 'text';
        const outlinedVariant = ownerState.variant === 'outlined';
        const softVariant = ownerState.variant === 'soft';
        const defaultStyle = {
            [`& .${PaginationItem_1.paginationItemClasses.root}`]: {
                ...(outlinedVariant && {
                    borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.24),
                }),
                [`&.${PaginationItem_1.paginationItemClasses.selected}`]: {
                    fontWeight: theme.typography.fontWeightSemiBold,
                    ...(outlinedVariant && {
                        borderColor: 'currentColor',
                    }),
                    ...(defaultColor && {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.08),
                        ...(filledVariant && {
                            color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                            backgroundColor: theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[100],
                            },
                        }),
                    }),
                },
            },
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                [`& .${PaginationItem_1.paginationItemClasses.root}`]: {
                    [`&.${PaginationItem_1.paginationItemClasses.selected}`]: {
                        ...(ownerState.color === color && {
                            // SOFT
                            ...(softVariant && {
                                color: theme.palette[color][lightMode ? 'dark' : 'light'],
                                backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.08),
                                '&:hover': {
                                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, 0.16),
                                },
                            }),
                        }),
                    },
                },
            }),
        }));
        return [defaultStyle, ...colorStyle];
    };
    return {
        MuiPagination: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
