"use strict";
/*
| Developed by Starton
| Filename : list.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.list = list;
/*
|--------------------------------------------------------------------------
| List
|--------------------------------------------------------------------------
*/
function list(theme) {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                },
            },
        },
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                },
            },
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    typography: 'subtitle2',
                },
            },
            styleOverrides: {
                root: {
                    margin: 0,
                },
                multiline: {
                    margin: 0,
                },
            },
        },
    };
}
