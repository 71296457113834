"use strict";
/*
| Developed by Starton
| Filename : toggle-button.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleButton = toggleButton;
const styles_1 = require("@mui/material/styles");
const ToggleButton_1 = require("@mui/material/ToggleButton");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| ToggleButton
|--------------------------------------------------------------------------
*/
function toggleButton(theme) {
    const rootStyles = (ownerState) => {
        const defaultStyle = {
            [`&.${ToggleButton_1.toggleButtonClasses.selected}`]: {
                borderColor: 'currentColor',
                boxShadow: '0 0 0 0.5px currentColor',
            },
        };
        const colorStyle = COLORS.map((color) => ({
            ...(ownerState.color === color && {
                '&:hover': {
                    borderColor: (0, styles_1.alpha)(theme.palette[color].main, 0.48),
                    backgroundColor: (0, styles_1.alpha)(theme.palette[color].main, theme.palette.action.hoverOpacity),
                },
            }),
        }));
        const disabledState = {
            [`&.${ToggleButton_1.toggleButtonClasses.disabled}`]: {
                [`&.${ToggleButton_1.toggleButtonClasses.selected}`]: {
                    color: theme.palette.action.disabled,
                    backgroundColor: theme.palette.action.selected,
                    borderColor: theme.palette.action.disabledBackground,
                },
            },
        };
        return [defaultStyle, ...colorStyle, disabledState];
    };
    return {
        MuiToggleButton: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.paper,
                    border: `solid 1px ${(0, styles_1.alpha)(theme.palette.grey[500], 0.08)}`,
                },
                grouped: {
                    margin: 4,
                    [`&.${ToggleButton_1.toggleButtonClasses.selected}`]: {
                        boxShadow: 'none',
                    },
                    '&:not(:first-of-type), &:not(:last-of-type)': {
                        borderRadius: theme.shape.borderRadius,
                        borderColor: 'transparent',
                    },
                },
            },
        },
    };
}
