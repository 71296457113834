/*
| Developed by Starton
| Filename : CheckboxInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const CheckboxInput = ({ name, checkboxProps, label, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, children: (field) => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { checked: Boolean(field.state.value), onChange: (event) => {
                    field.handleChange(event.target.checked);
                }, name: name, disableFocusRipple: true, disableRipple: true, disableTouchRipple: true, ...checkboxProps }), label: label })) }));
};
exports.CheckboxInput = CheckboxInput;
