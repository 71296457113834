"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/*
| Developed by Starton
| Filename : PageContainer.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const PageContainer = ({ children, drawerChildren, drawerWidth = 350, ...props }) => {
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: { display: 'flex', position: 'relative' }, children: [(0, jsx_runtime_1.jsx)(material_1.Box, { ...props, sx: {
                    ...props.sx,
                    margin: { xs: 3, sm: 4 },
                    marginRight: drawerChildren ? `${drawerWidth + 32}px` : undefined,
                    transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                    flexGrow: 1,
                }, children: children }), drawerChildren && ((0, jsx_runtime_1.jsx)(material_1.Drawer, { variant: "permanent", anchor: "right", sx: {
                    width: drawerWidth,
                    flexShrink: 0,
                    boxSizing: 'border-box',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }, children: drawerChildren }))] }));
};
exports.PageContainer = PageContainer;
