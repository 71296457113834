/*
| Developed by Dirupt
| Filename : BaseInput.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const form_1 = require("../form");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const BaseInput = ({ form, label, labelProps, labelIcon, labelContainerProps, description, descriptionProps, isOptional, children, name, optionalText, formControlProps, disabled, helperText, ...props }) => {
    const _optionalText = optionalText ? optionalText : '(optional)';
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(form.Field, { name: name, ...props, children: (field) => ((0, jsx_runtime_1.jsxs)(material_1.FormControl, { error: field.state.meta.errors?.length ? !!field.state.meta.isTouched : undefined, fullWidth: true, disabled: disabled, ...formControlProps, children: [label ? ((0, jsx_runtime_1.jsxs)(material_1.Box, { ...labelContainerProps, sx: {
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        mb: 1,
                        ...labelContainerProps?.sx,
                    }, children: [(0, jsx_runtime_1.jsxs)(material_1.Typography, { component: "label", htmlFor: `${name}-input`, "data-testid": `${name}-input-label`, variant: "body1", ...labelProps, children: [label, ' ', (0, jsx_runtime_1.jsx)(material_1.Typography, { component: "span", color: "primary.main", variant: "body1", children: isOptional ? _optionalText : '' })] }), labelIcon] })) : null, description ? ((0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "caption", color: "text.secondary", "data-testid": `${name}-input-description`, ...descriptionProps, children: description })) : null, children(field), (0, jsx_runtime_1.jsx)(form_1.FormFieldErorrs, { field: field }), helperText ? (0, jsx_runtime_1.jsx)(material_1.FormHelperText, { error: false, children: helperText }) : null] })) }));
};
exports.BaseInput = BaseInput;
