"use strict";
/*
| Developed by Starton
| Filename : dialog.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.dialog = dialog;
/*
|--------------------------------------------------------------------------
|
|--------------------------------------------------------------------------
*/
function dialog(theme) {
    return {
        MuiDialog: {
            styleOverrides: {
                paper: ({ ownerState }) => ({
                    boxShadow: 'none',
                    borderRadius: 0,
                    ...(!ownerState.fullScreen && {
                        margin: theme.spacing(2),
                    }),
                }),
                paperFullScreen: {
                    borderRadius: 0,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0, 3),
                },
                dividers: {
                    borderTop: 0,
                    borderBottomStyle: 'dashed',
                    paddingBottom: theme.spacing(3),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                    '& > :not(:first-of-type)': {
                        marginLeft: theme.spacing(1.5),
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backdropFilter: 'blur(4px)',
                },
            },
        },
    };
}
