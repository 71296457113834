/*
| Developed by Starton
| Filename : SelectInput.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const BaseInput_1 = require("./BaseInput");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const SelectInput = ({ selectProps, options, name, disabled, placeholder, ...props }) => {
    // Render
    //--------------------------------------------------------------------------
    return ((0, jsx_runtime_1.jsx)(BaseInput_1.BaseInput, { name: name, ...props, disabled: disabled, children: (field) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(material_1.Select, { value: field?.state?.value || '', onChange: (event) => field.handleChange?.(event.target.value), onBlur: field.handleBlur, "data-testid": `${name}-input`, ...selectProps, children: [placeholder && ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { disabled: true, value: "", "data-testid": `${name}-placeholder`, children: placeholder })), options.map(({ value, label, icon, ...option }, index) => ((0, jsx_runtime_1.jsxs)(material_1.MenuItem, { value: value, ...option, sx: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            ...option.sx,
                        }, "data-testid": `${name}-option-${value}`, children: [icon ? ((0, jsx_runtime_1.jsx)(material_1.Box, { component: "span", sx: {
                                    height: 16,
                                    '& svg': {
                                        width: 16,
                                        height: 16,
                                    },
                                }, "data-testid": `${name}-option-${value}-icon`, children: icon })) : null, label] }, index)))] }) })) }));
};
exports.SelectInput = SelectInput;
