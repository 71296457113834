"use strict";
/*
| Developed by Starton
| Filename : radio.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.radio = radio;
const Radio_1 = require("@mui/material/Radio");
/*
|--------------------------------------------------------------------------
| Radio
|--------------------------------------------------------------------------
*/
function radio(theme) {
    return {
        // CHECKBOX, RADIO, SWITCH
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    ...theme.typography.body2,
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: ({ ownerState }) => {
                    const { color } = ownerState;
                    return {
                        padding: theme.spacing(1),
                        ...(color === 'default' && {
                            [`&.${Radio_1.radioClasses.checked}`]: {
                                color: theme.palette.text.primary,
                            },
                        }),
                        [`&.${Radio_1.radioClasses.disabled}`]: {
                            color: theme.palette.action.disabled,
                        },
                    };
                },
            },
        },
    };
}
