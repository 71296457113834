"use strict";
/*
| Developed by Starton
| Filename : autocomplete.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.autocomplete = autocomplete;
const styles_1 = require("@mui/material/styles");
const SvgIcon_1 = require("@mui/material/SvgIcon");
const Autocomplete_1 = require("@mui/material/Autocomplete");
const css_1 = require("../../css");
/*
|--------------------------------------------------------------------------
| Autocomplete
|--------------------------------------------------------------------------
*/
function autocomplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    [`& span.${Autocomplete_1.autocompleteClasses.tag}`]: {
                        ...theme.typography.subtitle2,
                        height: 24,
                        minWidth: 24,
                        lineHeight: '24px',
                        textAlign: 'center',
                        padding: theme.spacing(0, 0.75),
                        color: theme.palette.text.secondary,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.16),
                    },
                },
                paper: {
                    ...(0, css_1.paper)({ theme, dropdown: true }),
                },
                listbox: {
                    padding: 0,
                    [`& .${Autocomplete_1.autocompleteClasses.option}`]: {
                        ...(0, css_1.menuItem)(theme),
                    },
                },
                endAdornment: {
                    [`& .${SvgIcon_1.svgIconClasses.root}`]: {
                        width: 18,
                        height: 18,
                    },
                },
            },
        },
    };
}
