"use strict";
/*
| Developed by Starton
| Filename : constants.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAV_CONFIG = void 0;
/*
|--------------------------------------------------------------------------
| Configuration
|--------------------------------------------------------------------------
|
| We keep this file separate from index.ts to allow for better tree-shaking
| and code-splitting in Next.js applications. Since these constants may be
| used in both client and server components, having them in a separate file
| allows Next.js to properly optimize the bundle size and avoid including
| unnecessary code in the server-side rendering process.
|
| By using the separate export path "./constants" instead of including it in
| the main index.ts, we ensure that server components only import the
| constants they need without pulling in client-side dependencies that may
| be present in the main index bundle.
|--------------------------------------------------------------------------
*/
exports.NAV_CONFIG = {
    drawerWidth: 300,
};
