/*
| Developed by Starton
| Filename : TableNoItems.tsx
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableNoItems = TableNoItems;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function TableNoItems({ colSpan, message = 'No data available', onClearFilters, onClearMessage = 'Clear filters', buttonProps, }) {
    return ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: (0, jsx_runtime_1.jsxs)(material_1.TableCell, { colSpan: colSpan, align: "center", children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "body1", color: "text.secondary", children: message }), onClearFilters ? ((0, jsx_runtime_1.jsx)(material_1.Button, { onClick: onClearFilters, ...buttonProps, children: onClearMessage })) : null] }) }));
}
