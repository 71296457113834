"use strict";
/*
| Developed by Starton
| Filename : accordion.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.accordion = accordion;
const Accordion_1 = require("@mui/material/Accordion");
const Typography_1 = require("@mui/material/Typography");
const AccordionSummary_1 = require("@mui/material/AccordionSummary");
/*
|--------------------------------------------------------------------------
| Accordion
|--------------------------------------------------------------------------
*/
function accordion(theme) {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    [`&.${Accordion_1.accordionClasses.expanded}`]: {
                        boxShadow: 'none',
                        backgroundColor: theme.palette.background.paper,
                    },
                    [`&.${Accordion_1.accordionClasses.disabled}`]: {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(1),
                    [`&.${AccordionSummary_1.accordionSummaryClasses.disabled}`]: {
                        opacity: 1,
                        color: theme.palette.action.disabled,
                        [`& .${Typography_1.typographyClasses.root}`]: {
                            color: 'inherit',
                        },
                    },
                },
                expandIconWrapper: {
                    color: 'inherit',
                },
            },
        },
    };
}
