/*
| Developed by Starton
| Filename : Toolbar.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toolbar = Toolbar;
const jsx_runtime_1 = require("react/jsx-runtime");
const styles_1 = require("@mui/material/styles");
const material_1 = require("@mui/material");
/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const AppBar = (0, styles_1.styled)(material_1.AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function Toolbar({ children, toolbarProps, ...appBarProps }) {
    return ((0, jsx_runtime_1.jsx)(AppBar, { position: "static", ...appBarProps, children: (0, jsx_runtime_1.jsx)(material_1.Toolbar, { ...toolbarProps, children: children }) }));
}
